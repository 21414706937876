.recs {
  padding-left: 96px;
}

.recs__container {
  padding: 28px 0;
  gap: 48px;
  padding-left: 74px;
  align-items: flex-start;
  overflow: hidden;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
    margin-bottom: 70px;
  }
}

.recs__care {
  position: relative;
  margin-bottom: 30px;
}

.recs__top {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;

  h2 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
    position: relative;
  }
}

.recs__slider {
  padding: 15px 10px;
}

.recs__slider .swiper-wrapper {
  align-items: stretch;
}

.recs__care .swiper-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 6px 3px hsla(0, 0%, 64%, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-default-white;
  color: $color-default-black;
  transition: $trans-default;
  border: none;

  &::after {
    font-size: 16px;
    border-radius: 50%;
    font-weight: 900;
  }

  &:hover,
  &:focus {
    background: $gradient-radial;
    color: $color-default-white;
  }

  &:active {
    transform: scale(0.9);
  }

  &-disabled {
    display: none;
  }
}

.recs__card,
.recs__analogs li {
  max-width: 267px;
  min-height: 434px;
  padding: 0 24px 16px;
  box-shadow: 0 0 6px 3px hsla(0, 0%, 64%, 0.55);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;

  img {
    width: 162px;
    height: 162px;
    margin: 0 auto;
  }

  b {
    border: 1px solid #fdc8d9;
    border-radius: 8px;
    padding: 6px 16px;
    color: #fdc8d9;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: fit-content;
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $fs-16-14;
    line-height: $fs-16-14;
    min-height: 48px;
  }
}

.recs__card.active {
  box-shadow: 0 0 6px 3px rgb(249, 117, 161, 0.5);
}

.recs__analogs {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex-grow: 1;

  li {
    max-width: 100%;
    max-height: fit-content;
  }

  &-none {
    font-size: $fs-p;
    line-height: $fs-p;
    text-align: center;
    margin: auto 0;
  }
}

.recs__stage {
  position: absolute;
  top: 24px;
  left: 24px;
  background-color: $main-color;
  color: $color-default-white;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  font-weight: 600;
}

.recs__card .recs__counter {
  display: flex;
  align-items: center;
  width: 100%;
  outline: 1px solid #dde1e6;
  border-radius: 12px;

  button {
    min-width: 48px;
    max-width: 48px;
    height: 100%;
    border: none;
    border-radius: 12px 0 0 12px;

    &:last-of-type {
      border-radius: 0 12px 12px 0;
    }
  }

  span {
    font-size: 24px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.recs__care .swiper-pagination {
  display: none;
}

.recs__total {
  background-color: $color-default-white;
  border-radius: 16px;
  bottom: 66px;
  box-shadow: 0 0 6px 3px hsla(0, 0%, 64%, 0.55);
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 32%;
  padding: 24px;
  position: fixed;
  right: 50px;
  z-index: 1001;

  button {
    border-radius: 8px;
  }

  span {
    display: flex;
    gap: 20px;
  }

  &.opened {
    right: 500px;
  }
}

.recs__care .error,
.recs__analogs .error {
  &::before,
  &::after {
    display: none;
  }
  a {
    display: none;
  }

  span {
    font-size: $fs-16-14;
    line-height: $fs-16-14;
  }

  p {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
    text-overflow: unset;
  }
}

@media (max-width: $mobile-width) {
  .recs {
    padding: 0;
    padding-bottom: 90px;
  }

  .recs__container {
    padding: 0 15px 50px;

    h1 {
      margin-bottom: 30px;
    }
  }

  .recs__top {
    margin-bottom: 20px;
  }

  .recs__card {
    max-width: 500px;
  }

  .recs__care {
    .swiper-button {
      width: 40px;
      height: 40px;

      &::after {
        font-size: 14px;
      }
    }

    .swiper-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      top: inherit;
      bottom: -20px;
    }

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      background: $gradient-radial;
    }

    &-addit {
      margin-bottom: 125px;
    }
  }

  .recs__total {
    right: 0;
    max-width: 100%;
    bottom: 56px;
    border-radius: 16px 16px 0 0;
    box-shadow: none;
    box-shadow: 0 -6px 14px 0 rgba(163, 163, 163, 0.55);
    width: 100%;
    border-bottom: 1px solid rgba(163, 163, 163, 0.55);
    padding: 15px;

    span {
      justify-content: space-between;
    }

    button {
      min-height: 40px;
    }

    &.opened {
      right: 0;
    }
  }

  .recs__analogs {
    padding-bottom: 100px;
  }
}
