.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: "768px") {
  .container {
    padding: 0 32px;
  }
}
