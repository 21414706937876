.profile {
  padding: 0 20px 0 96px;
}

.profile__container {
  padding: 30px 0;
}

.profile__content {
  display: flex;
  flex-direction: column;
  gap: 50px;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }
}

// tests
.profile__tests {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  li {
    min-height: 215px;
    max-width: 152px;
    width: 100%;
    min-width: 152px;
    display: flex;
    flex-direction: column;
  }

  p {
    padding: 24px 24px 0;
  }

  span {
    min-height: 40px;
    background: $gradient-radial;
    width: 100%;
    color: $color-default-white;
    margin-top: auto;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3,
  button {
    display: none;
  }

  a {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    flex: 1;
    transition: $trans-default;
    border: 1px solid $gradient-color-3;
    border-radius: 8px;

    &:hover,
    &:focus {
      border-color: $main-color;
      color: $main-color;

      span {
        background: $main-color;
      }
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

// data
.profile__data {
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  padding: 20px;
  border-radius: 8px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  li {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    color: $color-default-black;
    min-height: 40px;
    align-items: center;
    padding: 20px;
    border-radius: 8px;

    &:nth-child(odd) {
      background-color: rgb(145, 155, 236, 0.2);
    }

    &:nth-child(even) {
      background-color: rgb(145, 155, 236, 0.1);
    }

    span {
      font-weight: 600;
    }
  }

  strong,
  label {
    font-size: $fs-p;
    line-height: $fs-p;
    font-weight: 600;
  }

  input {
    background-color: $color-default-white;
    border: 1px solid $gradient-color-3;
    border-radius: 8px;
    font-size: 14px;
    min-height: 34px;
    padding: 0 24px;
  }

  .inactive {
    display: none;
  }
}

.profile__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__invite {
  display: flex;

  a {
    display: none;
  }

  button {
    min-width: 300px;
  }
}

.profile__invite-list {
  border-spacing: 0;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 8px;

  thead {
    background: $gradient-radial;

    tr {
      color: $color-default-white;
      width: 100%;
    }

    th {
      padding: 10px;
      font-size: $fs-16-14;
      line-height: $fs-16-14;
      text-align: left;
      vertical-align: middle;
    }
  }

  tbody {
    tr {
      color: $color-default-black;
      border-bottom: 1px solid $text-color-lightgrey;
      background-color: $color-default-white;
      transition: $trans-default;
    }

    tr:nth-child(even) {
      background-color: $main-color-secondary;
    }

    td {
      padding: 10px;
      font-size: 14px;
      line-height: 14px;

      a {
        background-color: $gradient-color-3;
      }
    }
  }
}

.profile__data-buttons {
  display: flex;
  gap: 60px;
  align-items: center;
}

// Newsletter

.profile__newsletter {
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .quill {
    width: 100%;
    border: 1px solid #91bbeb;
    border-radius: 12px;
    padding: 10px 24px;
    color: #474646;
    padding: 0;

    .ql-toolbar {
      border: none;
      border-bottom: 1px solid #91bbeb;
    }

    div {
      border: none;
    }

    .ql-editor {
      background-color: $color-default-white;
      border-radius: 0 0 16px 16px;
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

.profile__letter-title {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: $fs-16-14;
    line-height: $fs-16-14;
    font-weight: 500;
  }

  input {
    width: 100%;
    border: 1px solid #91bbeb;
    border-radius: 12px;
    padding: 10px 24px;
    color: #474646;
  }
}

.profile__letter-text {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: $fs-16-14;
    line-height: $fs-16-14;
    font-weight: 500;
  }
}

.profile__letter-recievers {
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: $fs-p;
    line-height: $fs-p;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 200px;
    overflow-y: auto;
  }

  label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
    gap: 15px;
    font-size: $fs-16-14;
    line-height: $fs-16-14;
    cursor: pointer;

    input {
      display: none;

      &:checked+.entrance__checkbox {
        border-color: #007bff;
        background-color: #007bff;
      }

      &:checked+.entrance__checkbox::after {
        content: "✓";
        color: white;
        font-size: 14px;
        font-weight: bold;
      }

      &:not(:checked):hover+.entrance__checkbox,
      &:not(:checked):focus+.entrance__checkbox {
        border-color: $main-color;
      }

      &:checked:hover+.entrance__checkbox,
      &:checked:focus+.entrance__checkbox {
        background-color: $main-color;
        border-color: $main-color;
      }
    }
  }
}

.profile__letter-actions {
  display: flex;
  gap: 30px;

  button {
    max-width: 200px;
    min-height: 30px;
    font-size: $fs-16-14;
    line-height: $fs-16-14;
  }
}

.profile__letter-submit {
  margin-top: 20px;
}

@media (max-width: $mobile-width) {
  .profile {
    padding: 0 20px 0 66px;

    .dashboard {
      display: flex;
      width: 56px;
      padding: 10px 10px 60px;
    }

    .dashboard__nav a {
      width: 36px;
      height: 36px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .container {
      padding: 0;
    }
  }

  .profile__content {
    gap: 20px;
  }

  .profile__data {
    gap: 20px;

    span {
      font-size: 14px;
      line-height: 14px;
    }

    li {
      gap: 10px;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    strong {
      margin: auto 0;
    }

    input {
      max-width: 100%;
      width: 100%;
      padding: 0 10px;
    }
  }

  .profile__invite {
    width: 100%;

    button {
      min-width: 100%;
    }
  }

  .profile__link,
  .profile__newsletter {
    gap: 10px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .profile__invite-list {
    display: block;
    white-space: nowrap;
    border-spacing: 0;
    box-shadow: none;
    border-radius: 8px;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: transparent;

      tr {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: stretch;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid $text-color-lightgrey;
        border-radius: 8px;
        background-color: $color-default-white;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:nth-child(even) {
          background-color: $main-color-secondary;
        }

        &.closed {
          height: fit-content;

          td {
            display: none;

            &:nth-child(2) {
              display: flex;
              width: 100%;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              height: fit-content;

              span {
                padding: 0 20px;
                width: fit-content;
                margin: 0;
              }
            }
          }
        }

        &.open {
          td {
            height: auto;
            flex-shrink: 1;
            justify-content: space-between;
          }
        }
      }

      td {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        line-height: 18px;
        min-width: 80px;
        word-wrap: break-word;
        padding: 0;

        a {
          padding: 0 10px;
        }
      }

      td::before {
        content: attr(data-label);
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
  }

  .profile__user {
    width: 100%;
  }
}