.share__list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  button {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    background: $gradient-radial;
    color: $color-default-white;
    cursor: pointer;

    &:hover,
    &:focus {
      transform: scale(1.1);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      color: $main-color;
    }

    &:active {
      transform: scale(0.95);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }
  }
}
