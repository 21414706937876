.test__nav {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    align-items: center;
    border-radius: 8px;
    position: relative;

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover,
    &:focus {
      background: $gradient-radial;
      color: $color-default-white;
    }

    &:active {
      transform: scale(0.9);
    }

    &.done {
      background-color: $main-color;
      color: $color-default-white;

      &::before {
        background-color: $gradient-color-1;
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background: none;
        color: inherit;
        transform: none;
      }
    }
  }
}

.mob__nav {
  width: 100%;
  display: none;

  @include vp-767 {
    display: flex;
  }

  .test__nav {
    flex-direction: row;
    gap: 16px;
    overflow: auto;
    padding: 20px 32px;
  }

  a {
    border: 1px solid #ebf5ff;
    width: fit-content;
    padding: 6px 16px;
    color: $main-color-secondary;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    height: fit-content;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      background-color: $main-color-secondary;
      color: $text-color;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 18px;
        height: 18px;
        border: 3px solid $color-default-white;
        border-radius: 50%;
        background-color: $main-color;
        bottom: -7px;
        right: -7px;
      }

      &:hover,
      &:focus {
        color: $color-default-white;
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        background: none;
        color: $main-color-secondary;
        transform: none;
      }
    }
  }
}
