.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
  

  h4 {
    font-size: 24px;
    color: #1c4595;
    margin-top: 20px;
  }

  span {
    display: inline-block;

    &::after {
      content: "";
      animation: dotsAnimation 1.5s infinite steps(3);
    }
  }
}

@keyframes dotsAnimation {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
