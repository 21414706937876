.docs__container {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ol {
    list-style: decimal;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  ul {
    list-style: disc;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      padding-left: 20px;
    }
  }

  h2 {
    margin-bottom: 15px;
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.rules__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 15px;

  img {
    &:hover,
    &:focus {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.rules__btns {
  display: flex;
  gap: 16px;
  align-items: center;

  button,
  a {
    display: flex;
    gap: 15px;
    align-items: center;
    width: fit-content;
    min-height: 48px;
    padding: 15px;
    font-size: 14px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.rules__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  padding: 20px 0;
  margin: 0 auto;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
}

.rules__dev {
  position: relative;
  padding-left: 60px;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../footer/scanderm.webp");
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 0;
  }

  &:hover,
  &:focus {
    color: $main-color;
  }

  &:active {
    transform: scale(0.9);
  }
}

@media (max-width: $mobile-width) {
  .rules__head {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .rules__container {
    ol {
      padding-left: 10px;
    }
  }

  .rules__btns {
    flex-direction: column;
    width: 100%;

    button,
    a {
      max-width: 100%;
      width: 100%;
      padding: 0;
    }
  }

  .rules__footer {
    flex-direction: column;
    gap: 20px;
  }

  .rules__dev {
    &::before {
      left: 10px;
    }
  }
}
