.notfound {
  display: flex;
  flex-grow: 1;
}

.notfound__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 50px 0;

  h2 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }

  p {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    font-weight: 500;
    max-width: 700px;
  }

  svg {
    width: 400px;
    height: 400px;
  }

  a {
    max-width: 300px;
    text-align: center;
  }
}

.notfound__animation {
  max-width: 300px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: $mobile-width) {
  .notfound__container {
    gap: 20px;
    padding: 20px 0;

    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .notfound__animation {
    max-width: 250px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}