.selection__list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  label {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid $main-color-secondary;
    transition: $trans-default;

    &:hover,
    &:focus {
      background: $gradient-radial;

      span {
        color: $color-default-white;
      }
    }

    &:active {
      transform: scale(0.9);
    }

    &.selected {
      background-color: #ffd1e5;
      border-color: #ff82aa;
      border: none;
    }
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }

  span {
    font-size: 16px;
    color: $text-color-grey;
    font-weight: 700;
    flex-grow: 1;
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}

.selection__selected {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main-color;
  border-radius: 50%;

  svg {
    color: $color-default-white;
    width: 12px;
    height: 10px;
  }
}