// Colors
$color-default-black: #000000;
$color-default-white: #ffffff;
$text-color-lightgrey: #808080;
$text-color-grey: #474646;
$text-color: #212529;
$main-color: #f975a1;
$main-color-secondary: #fdc8d9;
$color-error: #d70f0f;
$body-color: #fafbfe;

// Gradients
$gradient-color-1: #151f40;
$gradient-color-2: #113782;
$gradient-color-3: #919bec;

$gradient-radial: radial-gradient(
  74.91% 176.08% at 50% 50%,
  #151f40 17.72%,
  #113782 60.8%,
  #919bec 85.44%
);

// Typography
// переменная используемая в html для подключения скейлинга
$fs-default: 18px;
$lh-default: 24px;
$fw-default: 400;
$ff-mont: 'Montserrat', 'Arial', sans-serif;
$fs-h2: clamp(18px, 2.5vw, 48px);
$fs-p: clamp(14px, 2vw, 18px);
$fs-16-14: clamp(14px, 1.5vw, 16px);
$fs-16-24: clamp(16px, 2vw, 24px);

// Animation
$tf-default: ease;
$trans-default: 0.3s $tf-default;
$trans: scale(0.9);
// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);
$trans-600: 0.6s $tf-default;

// Viewports
$mobile-width: 768px;
$tablet-width: 1200px;

// Retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
