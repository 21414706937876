.survey {
  padding: 0 20px 0 96px;
}

.survey__container {
  display: flex;
  flex-direction: column;
  gap: 52px;
  padding: 28px 0;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }

  h2 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }

  h3 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }
}

.survey__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.survey__pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.survey__position {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    border: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;

    &:hover,
    &:focus {
      background-color: $main-color;
      color: $color-default-white;
    }

    &:active {
      transform: scale(0.9);
    }

    svg {
      width: 25px;
      height: 25px;
      transform: rotate(180deg);
    }
  }

  span {
    color: $text-color-lightgrey;
    display: flex;
    align-items: center;
    gap: 7px;

    b {
      font-weight: 900;
      color: $gradient-color-2;
    }
  }
}

.survey__dots {
  display: flex;
  gap: 16px;

  li {
    width: 24px;
    height: 24px;
    border: 1px solid $main-color;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active::before {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $main-color;
    }

    &.done {
      background-color: $main-color;
      color: $color-default-white;

      svg {
        width: 12px;
        height: 10px;
      }
    }
  }
}

.survey__question {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }

  label,
  textarea {
    padding: 20px 24px;
    border: 1px solid #91bbeb;
    border-radius: 16px;
    cursor: pointer;
    transition: $trans-default;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;

    &:hover,
    &:focus {
      border-color: $main-color;
      background: $gradient-radial;
      color: $color-default-white;
    }

    &:active {
      transform: scale(0.8);
    }

    &.selected {
      background-color: $main-color;
      color: $color-default-white;
    }
  }

  input:not([type="range"]) {
    position: absolute;
    opacity: 0;
  }

  button {
    margin-top: 40px;
    max-width: 50%;
  }
}

.survey__tooltip {
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: #f2f2f7;
  color: $main-color-secondary;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  margin-left: 8px;
  position: relative;

  &:hover,
  &:focus {
    background-color: $main-color;
    color: $color-default-white;
  }
}

.survey .survey__tooltip-text {
  background: $gradient-radial;
  max-width: 500px;
  width: 100%;
  border-radius: 16px;
  opacity: 1;
  z-index: 1000;
}

.survey__answers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.survey__question .survey__btns {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    max-width: 250px;
  }
}

.survey__slider {
  margin-top: 40px;
}

.survey__error {
  color: $color-error;
}

.survey__numbers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  li {
    font-size: 40px;
    line-height: 48px;
    color: #f2f2f7;
    font-weight: 900;
    display: flex;
    align-items: center;

    &.active {
      font-size: 96px;
      color: $text-color-grey;
    }
  }
}

.survey input.survey__age {
  padding: 20px 24px;
  border: 1px solid #91bbeb;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 18px;
  line-height: 24px;
  position: static;
  opacity: 1;
  max-width: 50%;
}

.survey__submit {
  max-width: 50%;
}

@media (max-width: $mobile-width) {
  .survey {
    padding: 0;
  }

  .survey__container {
    gap: 40px;
  }

  .survey__pager {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .survey__position button {
    width: 30px;
    height: 30px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .survey__dots li {
    width: 18px;
    height: 18px;

    &.active::before {
      width: 10px;
      height: 10px;
    }

    &.done svg {
      width: 10px;
      height: 8px;
    }
  }

  .survey__question {
    label {
      padding: 15px 17px;
      font-size: $fs-p;
      line-height: $fs-p;
    }
  }

  .survey__answers {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
    }
  }

  .survey__numbers {
    li {
      font-size: 32px;
      line-height: 40px;

      &.active {
        font-size: 48px;
        line-height: 54px;
      }
    }
  }

  .survey__success {
    min-height: 20vh;
  }

  .survey__fireworks {
    height: 100px;
  }

  .survey input.survey__age {
    padding: 10px 15px;
    max-width: 100%;
  }

  .survey__submit {
    max-width: 100%;
  }

  .survey .survey__container .survey__tooltip-text {
    background: $gradient-radial;
    max-width: 80%;
    width: 100%;
    border-radius: 16px;
    opacity: 1;
    top: 50px;
  }

  .survey__tooltip {
    width: 30px;
    height: 30px;
  }
}
