.dashboard {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  height: 100vh;
  width: 76px;
  box-shadow: 4px 0 4px 0 rgba(194, 202, 228, 0.2);
  padding: 24px 14px;
  z-index: 5;
  background-color: $color-default-white;

  a {
    &:hover img,
    &:focus img {
      transform: scale(1.1);
    }

    &:active img {
      transform: scale(0.9);
    }

    &.active {
      background-color: $main-color-secondary;
      color: $text-color;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 18px;
        height: 18px;
        border: 3px solid $color-default-white;
        border-radius: 50%;
        background-color: $main-color;
        bottom: -7px;
        right: -7px;
      }
    }
  }
}

.dashboard__nav {
  margin-top: auto;

  a {
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    align-items: center;
    border-radius: 8px;
    position: relative;

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover,
    &:focus {
      background: $gradient-radial;
      color: $color-default-white;
    }

    &:active {
      transform: scale(0.9);
    }

    &.done {
      background-color: $main-color;
      color: $color-default-white;

      &::before {
        background-color: $gradient-color-1;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  li {
    &:last-child {
      margin-top: 60px;
    }
  }
}

.dashboard__bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  a {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    border: 1px solid $text-color-grey;

    &:hover,
    &:focus {
      background: $gradient-radial;
      color: $color-default-white;
    }

    &:active {
      transform: scale(0.9);
    }

    &.dashboard__home {
      background-color: $color-default-black;
      color: $color-default-white;
    }
  }
}

.dashboard__logout {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $gradient-color-2;
  }

  &:active {
    transform: scale(0.9);
  }
}

@media (max-width: $mobile-width) {
  .dashboard {
    display: none;
  }
}
