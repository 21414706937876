.footer {
  background-color: $text-color-grey;
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    background-image: url("./footer_left.webp");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    width: 728px;
    height: 659px;
    top: -81px;
    left: 0;
    z-index: 0;
  }

  &::after {
    position: absolute;
    content: "";
    background-image: url("./footer_right.webp");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    width: 728px;
    height: 827px;
    top: 135px;
    right: 0;
    z-index: 0;
  }
}

.footer__container {
  padding: 160px 0 24px;
  display: flex;
  flex-direction: column;
}

.footer__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 5;
  padding-bottom: 52px;
  border-bottom: 1px solid $text-color-lightgrey;
}

.footer__logo {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }

  &:focus {
    outline: none;
    transform: rotate(3deg);
    box-shadow: 0 0 10px rgba(0, 128, 255, 0.6);
  }

  &:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
  }
}

.footer__links {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a,
  button {
    font-size: $fs-p;
    line-height: $fs-p;
    color: $color-default-white;
    transition: $trans-default;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;

    &:hover,
    &:focus {
      color: $main-color;
    }

    &:active {
      color: $main-color-secondary;
    }
  }
}

.footer__dev {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 280px;

  div {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;

    a {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main-color;
      color: $color-default-white;

      &:hover,
      &:focus {
        background-color: $text-color;
        color: $main-color;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.footer__dev-website {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  transition: $trans-default;

  &::before {
    position: absolute;
    content: "";
    background-image: url("./scanderm.webp");
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: 0 0;
    left: -56px;
  }

  h4 {
    font-size: 16px;
    line-height: 20px;
    color: $color-default-white;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: $color-default-white;
  }

  &:hover p,
  &:focus p {
    color: $main-color;
  }

  &:active {
    color: $main-color-secondary;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 0;
  gap: 20px;
  z-index: 5;

  span {
    font-size: 14px;
    line-height: 18px;
    color: $color-default-white;
  }
}

.footer__nav {
  ul {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  a {
    font-size: 14px;
    line-height: 18px;
    color: $color-default-white;

    &:hover,
    &:focus {
      color: $main-color;
    }

    &:active {
      color: $color-default-black;
    }
  }
}
.footer--other .footer__container {
  padding-top: 50px;
}

.footer--other .footer__links {
  li:first-child {
    display: none;
  }
}

@media (max-width: $mobile-width) {
  .footer__container {
    padding: 48px 0;
  }

  .footer__top {
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: none;
  }

  .footer__logo {
    margin-bottom: 40px;
  }

  .footer__links {
    margin-bottom: 40px;
  }

  .footer__dev {
    align-self: flex-start;
    padding-left: 56px;
    max-width: 100%;
  }

  .footer__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0 0;
    gap: 20px;

    span {
      font-size: 14px;
      line-height: 18px;
      color: $color-default-white;
    }
  }

  .footer__nav {
    display: none;
    ul {
      display: flex;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: $color-default-white;

      &:hover,
      &:focus {
        color: $main-color;
      }

      &:active {
        color: $color-default-black;
      }
    }
  }
}
