.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.modal {
  background: #fff;
  padding: 30px 48px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 90%;
  width: 471px;
  animation: slideIn 0.3s ease-out;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: clamp(16px, 1.5vw, 24px);
    line-height: clamp(20px, 1.5vw, 30px);
    font-weight: 900;
    padding-right: 30px;
  }
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 27px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  &:hover,
  &:focus {
    color: $main-color;
  }

  &:active {
    transform: scale(0.3);
  }
}

.modal__title {
  min-height: 34px;
  display: flex;
  align-items: center;
}

.modal__content {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  gap: 34px;
  height: auto;
  flex: 1;
  overflow-y: auto;
}

.modal__description {
  font-size: $fs-p;
  line-height: $fs-p;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

// ask

.ask__form {
  display: flex;
  flex-direction: column;
  gap: 34px;
  height: 100%;

  input,
  textarea {
    width: 100%;
    border: 1px solid #91bbeb;
    border-radius: 16px;
    padding: 19px 24px;
    color: $text-color-grey;

    &::placeholder {
      color: #91bbeb;
    }
  }
}

.ask__error {
  color: $color-error;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

.ask__submit {
  margin-top: auto;
}

.ask__success {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../modal/modal_success_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 center;
}

.ask__submitted {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  width: 96px;
  height: 96px;
  background: $gradient-radial;
  border-radius: 50%;
  color: $color-default-white;
}

.ask__countdown {
  text-align: center;
}

@media (max-width: $mobile-width) {
  .modal {
    max-width: 100%;
    width: 100%;
    max-height: 95vh;
    margin-top: auto;
    border-radius: 12px 12px 0 0;
    padding: 20px 18px;

    h2 {
      font-size: clamp(16px, 1.5vw, 24px);
      line-height: clamp(20px, 1.5vw, 30px);
      font-weight: 900;
    }
  }

  .modal__close {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .modal__content {
    margin-top: 25px;
    gap: 20px;
    padding-bottom: 70px;
  }

  // ask
  .ask__form {
    gap: 24px;

    input,
    textarea {
      border-radius: 12px;
      padding: 14px 18px;
      color: $text-color-grey;

      &::placeholder {
        color: #91bbeb;
      }
    }
  }

  .ask__error {
    font-size: 12px;
    line-height: 16px;
  }

  .ask__submit {
    margin-top: auto;
  }
}
