.btn {
  min-height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  border: none;
  background-color: transparent;
  color: $color-default-white;
  font-weight: 700;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;

  &:active {
    transform: scale(90%);
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &--white {
    background-color: $color-default-white;
    color: $text-color-grey;

    &:hover,
    &:focus {
      background-color: $main-color;
      color: $color-default-white;
    }
  }

  &--trans {
    border: 2px solid $color-default-white;

    &:hover,
    &:focus {
      background-color: $main-color;
      color: $color-default-white;
      border-color: $color-default-white;
    }
  }

  &--pink {
    background-color: $main-color;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: $text-color-grey;
      color: $main-color;
    }
  }

  &--transblue {
    background-color: transparent;
    color: $gradient-color-2;
    border: 1px solid $gradient-color-2;
    border-radius: 12px;

    &:hover,
    &:focus {
      border-color: $main-color;
      color: $main-color;
      background: $gradient-radial;
    }
  }
}

.btn--blue {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  background: radial-gradient(circle, #151f40 0%, #113782 50%, #919bec 100%);
  cursor: pointer;
  outline: none;
  transition: all 0.5s ease-in-out;
  animation: gradientAnimation 5s ease infinite;

  &:not(:disabled):hover {
    animation: gradientAnimationHover 5s ease infinite;
  }

  &:not(:disabled):focus {
    animation: gradientAnimationFocus 5s ease infinite;
  }

  &:not(:disabled):active {
    animation: gradientAnimationActive 3s ease infinite;
  }

  &:disabled {
    opacity: 0.1;
    cursor: not-allowed;
  }
}

@keyframes gradientAnimation {
  0% {
    background: radial-gradient(circle, #151f40 0%, #113782 50%, #919bec 100%);
  }
  50% {
    background: radial-gradient(circle, #113782 0%, #151f40 50%, #b0c3f1 100%);
  }
  100% {
    background: radial-gradient(circle, #151f40 0%, #113782 50%, #919bec 100%);
  }
}

@keyframes gradientAnimationHover {
  0% {
    background: radial-gradient(circle, #2b3a67 0%, #264d9b 50%, #b0c3f1 100%);
  }
  50% {
    background: radial-gradient(circle, #264d9b 0%, #2b3a67 50%, #a0b6e7 100%);
  }
  100% {
    background: radial-gradient(circle, #2b3a67 0%, #264d9b 50%, #b0c3f1 100%);
  }
}

@keyframes gradientAnimationFocus {
  0% {
    background: radial-gradient(circle, #1d274c 0%, #1a3c82 50%, #a0b6e7 100%);
  }
  50% {
    background: radial-gradient(circle, #1a3c82 0%, #1d274c 50%, #8192d1 100%);
  }
  100% {
    background: radial-gradient(circle, #1d274c 0%, #1a3c82 50%, #a0b6e7 100%);
  }
}

@keyframes gradientAnimationActive {
  0% {
    background: radial-gradient(circle, #0f1728 0%, #0f2d61 50%, #8192d1 100%);
  }
  50% {
    background: radial-gradient(circle, #0f2d61 0%, #0f1728 50%, #b0c3f1 100%);
  }
  100% {
    background: radial-gradient(circle, #0f1728 0%, #0f2d61 50%, #8192d1 100%);
  }
}

@media (max-width: $mobile-width) {
  .btn {
    min-height: 48px;
  }
}
