.result {
  padding: 0 20px 0 96px;
}

.result__container {
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 28px 0;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }
}

.result__data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 140px 64px auto;
  grid-template-areas:
    "topleft topmid topright"
    "topleft midmid midright"
    "bottom bottom bottom";
  gap: 24px;
}

.result__section {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;
  padding: 24px 48px;
  gap: 12px;

  h2 {
    font-size: $fs-p;
    line-height: 24px;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: $text-color-lightgrey;
    text-align: center;
  }
}

.result__improve {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  border-bottom: 1px dashed $color-default-black;
  cursor: pointer;
  color: $text-color;
  padding: 0;
  padding-bottom: 2px;
  padding: 5px;

  &:hover,
  &:focus {
    color: $main-color;
    border-color: $gradient-color-1;
  }

  &:active {
    background-color: $main-color;
    border: none;
    color: $color-default-white;
    border-radius: 12px;
  }
}

.result__index {
  align-items: center;

  .spinner {
    min-height: 100px;

    h4 {
      display: none;
    }
  }

  .error {
    flex-direction: row;
    padding: 0;
    width: 100%;

    &::before,
    &::after,
    .error__content {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.result__age {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
    font-weight: 900;
    color: $gradient-color-2;
  }

  .spinner {
    width: 100%;

    h4 {
      display: none;
    }
  }

  .error {
    flex-direction: row;
    padding: 0;
    width: 100%;

    &::before,
    &::after,
    .error__content {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.result__share {
  padding: 0;
  display: flex;
  gap: 20px;
  font-size: 16px;
  line-height: 20px;
  border: none;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
}

.result__next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result__circle {
  svg {
    width: 103px;
    height: 102px;
  }

  & .CircularProgressbar-text {
    font-weight: 900;
  }
}

.result__details {
  overflow: hidden;
}

.result__conds-top {
  span {
    font-size: 14px;
    line-height: 18px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      left: 50%;
      top: 50px;
      border: 1px dashed #dde1e6;
      width: 1px;
      height: 80vh;
    }

    &:first-child {
      color: #c2cae4;
    }

    &:nth-child(2) {
      color: #00cf56;
    }

    &:nth-child(3) {
      color: #ffa800;
    }

    &:nth-child(4) {
      color: #e90000;
    }
  }
}

.result__conds-top,
.result__conds-data li {
  display: grid;
  grid-template-columns: 30% 1fr;
  column-gap: 30px;
  align-items: center;
}

.result__conds-points {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result__conds-data {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 15px;

  .error {
    flex-direction: row;
    padding: 0;
    width: 100%;

    &::before,
    &::after,
    .error__content {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: $text-color;
  }
}

.result__conds-bar {
  padding-left: 14px;
  border-radius: 0 6px 6px 0;
  height: 16px;
  z-index: 5;

  &--filled {
    background-color: $main-color;
    height: 100%;
    border-radius: 0 8px 8px 0;
  }
}

.result__improve-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: auto;
  padding-left: 20px;
}

@media (max-width: $mobile-width) {
  .result {
    padding: 0;
  }

  .result__data {
    display: flex;
    flex-direction: column;
  }

  .result__section {
    padding: 15px;
    h2 {
      line-height: 18px;
    }
  }

  .result__age {
    align-items: center;
  }

  .result__conds-data {
    gap: 10px;

    span {
      font-size: $fs-p;
      line-height: 18px;
    }
  }
}
