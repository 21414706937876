.photo {
  padding: 0 20px 0 96px;
  margin-top: 30px;
}

.photo__container {
  display: flex;
  flex-direction: column;
  gap: 52px;
  padding: 28px 0;
}

.photo__title {
  display: flex;
  gap: 24px;
  align-items: center;

  span {
    border: 2px solid $text-color;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  h1 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }
}

// photo__guide

.photo__guide {
  display: flex;
  align-items: stretch;
  gap: 24px;

  @include vp-1199 {
    flex-direction: column;
  }

  &-main {
    display: flex;
    align-items: stretch;
    gap: 33px;
    padding: 24px;
    box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
    border-radius: 16px;
    max-width: 850px;
    width: 100%;

    img {
      object-fit: cover;
      min-width: 293px;
      min-height: 318px;
      object-position: center;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
      font-size: $fs-p;
      line-height: $fs-p;
      color: $text-color;
    }

    h2 {
      font-size: $fs-p;
      line-height: $fs-p;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    li {
      font-size: $fs-16-14;
      line-height: $fs-16-14;
      color: $text-color;
    }
  }

  button {
    margin-top: auto;
  }

  &-addit {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 24px;
    box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
    border-radius: 16px;
    max-width: 269px;
    width: 100%;
    flex-grow: 1;

    h2 {
      font-size: $fs-p;
      line-height: $fs-p;
    }

    p {
      font-size: $fs-16-14;
      line-height: $fs-16-14;
      color: $text-color-lightgrey;
    }

    @include vp-1199 {
      max-width: 100%;
      justify-content: center;
      text-align: center;

      img {
        margin: 0 auto;
      }
    }
  }
}

// photo__upload
.photo__upload {
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;

  @include vp-1199 {
    flex-direction: column;
    padding: 15px;
  }
}

.photo__upload-form {
  display: flex;
  align-items: stretch;
  position: relative;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;
  width: 100%;
  min-width: 100%;
  padding: 15px;

  @include vp-1199 {
    flex-direction: column;
    align-items: center;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }

  label {
    @include vp-1199 {
      display: flex;
      justify-content: center;
      min-width: 80%;
      min-height: 100%;
    }
  }

  img {
    min-height: 473px;
    max-height: 473px;
    max-width: 561px;
    min-width: 561px;
    object-fit: cover;
    border-radius: 16px;
    object-position: center;
    user-select: none;
    pointer-events: auto;
  }

  label {
    user-select: none;
    pointer-events: auto;
  }

  button[type="submit"] {
    max-height: 48px;
    align-self: center;
    width: 100%;
  }
}

.photo__upload-placeholder {
  min-height: 473px;
  max-width: 561px;
  min-width: 561px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #fafbfe;
  border-radius: 16px;
  cursor: pointer;
  transition: $trans-default;

  &:hover,
  &:focus {
    background: $gradient-radial;
    color: $main-color;
    svg {
      color: $main-color;
    }
  }

  &:active {
    background: $gradient-radial;
    color: $main-color;
    transform: scale(0.9);
    svg {
      color: $main-color;
    }
  }

  span {
    text-align: center;
  }
}

.photo__upload-error {
  position: absolute;
  bottom: -40px;
  left: 0;
  color: $color-error;
  font-size: $fs-p;
  line-height: $fs-p;
}

.photo__upload-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  height: 100%;
  flex-grow: 1;
  margin: auto 0;

  @include vp-1199 {
    align-items: center;

    h2 {
      text-align: center;
    }
  }

  h2 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }

  p {
    font-size: $fs-16-14;
    line-height: $fs-16-14;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    padding-left: 20px;
    list-style: disc;
  }

  h3 {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
  }

  li {
    font-size: $fs-p;
  }

  button {
    display: flex;
    margin-top: auto;
    align-items: center;
    gap: 20px;
    font-size: $fs-16-14;
  }
}

// photo__analysis

.photo__analysis {
  display: flex;
  align-items: stretch;
  position: relative;
  box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
  border-radius: 16px;

  img {
    min-height: 473px;
    max-height: 473px;
    max-width: 561px;
    min-width: 561px;
    object-fit: cover;
    border-radius: 16px;
    overflow: hidden;
    object-position: center;
    user-select: none;
    pointer-events: auto;
  }
}

.photo__analysis-container {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fafbfe;
  border-radius: 16px;
  min-width: 561px;
  user-select: none;
  pointer-events: auto;
}

.photo__analysis-progress {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 30px;
  gap: 40px;
  user-select: none;
  pointer-events: auto;

  span {
    font-size: $fs-16-24;
    line-height: $fs-16-24;
    font-weight: 700;
  }

  progress {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    border-radius: 15px;
    background-color: #f0f0f0;
    color: #6a1b9a;
    overflow: hidden;
    border: none;
  }

  progress::-webkit-progress-bar {
    background-color: #f0f0f0;
    border-radius: 15px;
  }

  progress::-webkit-progress-value {
    background: $gradient-radial;
    border-radius: 15px;
  }

  progress::-moz-progress-bar {
    background: $gradient-radial;
    border-radius: 15px;
  }
}

@media (max-width: $mobile-width) {
  .photo {
    padding: 0 0 76px 0;
  }

  .photo__container {
    padding: 0;
  }

  .photo__title {
    display: none;
  }

  .photo__guide-main {
    flex-direction: column;
    max-width: 100%;

    button {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .photo__guide-img {
    margin: 0 auto;
    img {
      max-width: 256px;
      max-height: 256px;
      width: 100%;
      height: 100%;
      min-width: 256px;
      min-height: 256px;
      border-radius: 16px;
    }
  }

  .photo__guide-addit {
    display: none;
  }

  .photo__upload {
    box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
    border-radius: 16px;
  }

  .photo__upload-form {
    flex-direction: column;

    label {
      width: 100%;
    }

    img {
      margin: 0 auto;
      min-width: 100%;
      min-height: 256px;
      max-width: 100%;
      max-height: 290px;
      max-width: 100%;
    }

    button {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .photo__upload-placeholder {
    margin: 0 auto;
    min-width: 100%;
    min-height: 256px;
    padding: 10px;

    span {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .photo__upload-text {
    padding: 0;
    margin-top: 20px;
  }

  .photo__upload-error {
    position: static;
    margin-top: 10px;
    text-align: center;
  }

  .photo__analysis {
    flex-direction: column;
    padding: 15px;
  }

  .photo__analysis-container {
    min-width: 100%;

    img {
      min-width: 100%;
      min-height: 256px;
      max-width: 100%;
      max-height: 290px;
      max-width: 100%;
    }
  }

  .photo__analysis-progress {
    padding: 0;
    margin-top: 20px;
  }
}
