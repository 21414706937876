.entrance {
  display: flex;
  height: 100%;
  flex-grow: 1;
  position: relative;
  flex: 1;

  &::before {
    position: absolute;
    content: "";
    background-image: url("../content/index/instructions/instr_bg.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 728px;
    height: 827px;
    z-index: 0;
  }

  &::after {
    position: absolute;
    content: "";
    background-image: url("../content/index/instructions/instr_bg_right.webp");
    background-size: cover;
    background-repeat: no-repeat;
    width: 728px;
    min-height: 827px;
    right: -144px;
    bottom: 0;
    z-index: 0;
    top: -100px;
    right: 0;
  }

  & .error {
    &::before,
    &::after {
      display: none;
    }
  }
}

.entrance__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 70px 0;
}

.entrance__content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  max-width: 375px;
  margin: 0 auto;
  z-index: 1;
  justify-content: center;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
    text-align: center;
  }

  p {
    font-size: $fs-p;
    line-height: $fs-p;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-size: 12px;
      line-height: 16px;
      color: $text-color-lightgrey;
    }

    input {
      background-color: $color-default-white;
      border: 1px solid $gradient-color-3;
      border-radius: 8px;
      font-size: 14px;
      min-height: 48px;
      padding: 0 24px;
    }
  }
}

.entrance__reset {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.entrance__error {
  font-size: 14px;
  line-height: 16px;
  color: $color-error;
}

.entrance__bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  span {
    font-size: 12px;
    line-height: 16px;
    color: $text-color-lightgrey;

    a {
      color: $main-color;
      border-bottom: 1px solid $main-color;

      &:hover,
      &:focus {
        color: $gradient-color-2;
        border-color: $gradient-color-2;
      }

      &:active {
        background: $gradient-radial;
        color: $color-default-white;
      }
    }
  }
}

.entrance .entrance__agrees {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:checked + .entrance__checkbox {
      border-color: #007bff;
      background-color: #007bff;
    }

    &:checked + .entrance__checkbox::after {
      content: "✓";
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    &:not(:checked):hover + .entrance__checkbox,
    &:not(:checked):focus + .entrance__checkbox {
      border-color: $main-color;
    }

    &:checked:hover + .entrance__checkbox,
    &:checked:focus + .entrance__checkbox {
      background-color: $main-color;
      border-color: $main-color;
    }
  }

  label {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    font-size: 14px;
    color: $text-color-lightgrey;
    gap: 10px;
    align-items: center;
  }

  p {
    margin-left: 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: start;

    a {
      color: $main-color;

      &:hover,
      &:focus {
        color: $gradient-color-3;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.entrance__checkbox {
  min-width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: all 0.3s ease;
}

@media (max-width: $mobile-width) {
  .entrance {
    z-index: 5;
    overflow: hidden;

    &::after {
      display: none;
    }
  }
}
