.header {
  width: 100%;
  position: absolute;
  background-color: transparent;
  z-index: 5;

  &--white {
    position: static;
    background-color: transparent;
  }
}

.header__container {
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.header__top {
  display: flex;
  justify-content: space-between;
}

.header__nav,
.header__ask {
  display: flex;
  gap: 10px;
  align-items: center;

  button,
  a {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 18px;
    color: $color-default-white;
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    height: 100%;
    font-weight: 500;
    transition: transform 0.1s ease-in-out, color 0.3s ease;

    &:hover,
    &:focus {
      color: $main-color;
    }

    &:active {
      color: $color-default-white;
      transform: scale(0.95);
    }

    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 300%;
      height: 300%;
      background: rgba($main-color-secondary, 0.5);
      transform: translate(-50%, -50%) scale(0);
      border-radius: 50%;
      transition: transform 0.5s ease-out;
    }

    &:active::after {
      transform: translate(-50%, -50%) scale(1);
      transition: transform 0.3s ease-out;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.header__ask {
  display: flex;
  gap: 0;
  align-items: center;

  a {
    padding: 8px 16px;
  }
}

.header__bottom {
  display: flex;
  justify-content: space-between;
}

.header__bottom img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }

  &:focus {
    outline: none;
    transform: rotate(3deg);
    box-shadow: 0 0 10px rgba(0, 128, 255, 0.6);
  }

  &:active {
    transform: scale(0.95);
    transition: transform 0.1s ease;
  }
}

.header__personal {
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    display: none;
  }
}

.header__personal-ask {
  min-width: 175px;
}

.header__personal-login {
  min-width: 111px;
}

.header__personal-logout {
  min-width: 100px;
}

.header--white {
  .header__bottom a {
    display: flex;
    align-items: center;
  }

  .header__nav button {
    color: $text-color-lightgrey;

    &:hover,
    &:focus {
      color: $main-color;
    }

    &:active {
      color: $gradient-color-2;
    }
  }

  .header__ask-link {
    color: $text-color-grey;

    &:active {
      color: $gradient-color-2;
    }
  }
}

@media (max-width: $mobile-width) {
  .header__ask {
    display: none;
  }

  .header__bottom {
    display: flex;
    justify-content: space-between;

    a {
      max-height: 48px;
    }
  }

  .header__bottom img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      filter: brightness(1.2);
    }

    &:focus {
      outline: none;
      transform: rotate(3deg);
      box-shadow: 0 0 10px rgba(0, 128, 255, 0.6);
    }

    &:active {
      transform: scale(0.95);
      transition: transform 0.1s ease;
    }
  }

  .header__personal {
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      display: block;
    }
  }

  .header__personal-ask {
    min-width: 48px;
    font-size: 0;
    max-width: 48px;
    color: $color-default-white;
    color: $text-color;
    border-radius: 8px;
  }

  .header__personal-login {
    min-width: 48px;
    font-size: 0;
    min-height: 48px;
    max-width: 48px;
    color: $color-default-white;
    border-radius: 8px;
    outline: 2px solid $color-default-white;
    background-color: transparent;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .header__personal-logout {
    display: none;
  }

  .header--white {
    .header__personal-ask {
      background-color: transparent;
      background: none;
      max-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-default-white;
      padding: 0;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    .header__personal-login {
      background: $color-default-white;
      outline: none;
      color: $gradient-color-1;
    }
  }
}
