.faq {
  padding: 0 20px 0 96px;
}

.faq__container {
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 28px 0;

  h1 {
    font-size: $fs-h2;
    line-height: $fs-h2;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  li {
    padding: 20px 24px;
    box-shadow: 0 0 6px 1px rgba(163, 163, 163, 0.55);
    border-radius: 12px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &.active svg {
        transition: $trans-default;
        transform: rotate(-90deg);
    }
  }

  span {
    font-size: $fs-p;
    line-height: $fs-p;
    font-weight: 700;
    padding-right: 30px;
  }

  p {
    font-size: $fs-16-14;
    line-height: $fs-p;
    padding: 20px;
    color: $text-color-lightgrey;
    font-weight: 500;
  } 
}

.faq__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  svg {
    transform: rotate(90deg);
    width: 14px;
  }
}

.faq__answer {
  overflow: hidden;
  margin-left: 10px;
}

@media(max-width: $mobile-width) {
  .faq {
    padding: 0;
  }

  .faq__container {
    gap: 30px;

    ul {
      gap: 15px;
    }

    li {
      padding: 15px;
    }

    p {
      margin-top: 20px;
      padding: 0;
    }

    span {
      width: 100%;
      padding-right: 10px;
    }
  }

  .faq__answer {
    margin: 0;
  }
}