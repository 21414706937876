.about {
  background: radial-gradient(
    74.91% 176.08% at 50% 50%,
    #151f40 17.72%,
    #113782 60.8%,
    #919bec 85.44%
  );
  overflow: hidden;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    background-image: url("./about_square.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: calc(100% - 96px);
    height: calc(100% - 82px);
    left: 48px;
    top: 38px;
  }

  &::after {
    position: absolute;
    content: "";
    background-image: url("./about_left.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 728px;
    height: 659px;
    top: 0;
    left: 0;
    z-index: 0;
  }

  @media (max-width: "1412px") {
    &::before {
      display: none;
    }
  }
}

.about__container {
  padding: 187px 0 120px;
  display: flex;
}

.about__text {
  display: flex;
  flex-direction: column;
  max-width: 776px;
  width: 100%;
  z-index: 1;

  h2 {
    font-size: $fs-h2;
    line-height: $fs-h2;
    font-weight: 900;
    color: $color-default-white;
    margin-bottom: 32px;
  }

  p {
    color: $color-default-white;
    max-width: 470px;
    margin-bottom: 145px;
    font-size: $fs-p;
  }

  a {
    min-height: 64px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    max-width: 375px;
    margin-bottom: 16px;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: $color-default-white;

    a {
      position: relative;
      color: $color-default-white;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      text-decoration: none;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background: $color-default-white;
        transition: transform 0.4s ease-out;
      }

      &:hover,
      &:focus {
        color: $main-color;
      }

      &:active::after {
        transform: scaleX(0);
      }
    }
  }
}

.about__picture {
  img {
    width: 872px;
    max-height: 940px;
    object-fit: contain;
    position: absolute;
    bottom: -214px;
    right: -100px;
  }
}

@media (max-width: $mobile-width) {
  .about__container {
    padding: 145px 0 60px;
  }

  .about__text {
    max-width: 100%;

    h2 {
      margin-bottom: 24px;
    }

    p {
      max-width: 100%;
      margin-bottom: 43px;
    }

    a {
      max-width: 375px;
      min-height: 48px;
    }
  }

  .about__picture {
    img {
      width: 387px;
      max-height: 550px;
      object-fit: contain;
      position: absolute;
      bottom: -70px;
      right: 0;
    }
  }
}
