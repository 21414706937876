.success {
    margin-top: 50px;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 30px;

    span {
      text-align: center;
    }
  }
  
  .success__fireworks {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .success__star {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #007bff;
    border-radius: 50%;
    opacity: 0;
  }
  
  @media (max-width: $mobile-width) {
    .success {
      min-height: 20vh;

      span {
        text-align: center;
      }
    }
  
    .success__fireworks {
      height: 100px;
    }
  }
  